import React, { useState, useContext, useEffect } from "react";
import classes from "./ProfileForm.module.css";

import { Link } from "react-router-dom";
import LabelForm from "../labelFormPage/LabelForm";
import InputForm from "../inputFormPage/InputForm";
import AuthContext from "../../../store/auth-context";
import { useTranslation } from "react-i18next";
import useHttp from "../../../hook/use-fetch";
import Helmet from "react-helmet";

const ProfileForm = () => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);

  const [edit, setEdit] = useState(true);
  const [saveEdit, setSaveEdit] = useState(false);
  const [profile, setProfile] = useState();

  const { loading, error, sendRequest: fetchTasks } = useHttp();

  useEffect(() => {
    const applyRes = (res) => {};

    const getData = (data) => {
      setProfile(data);
    };

    fetchTasks(
      {
        url: "https://backend.curecare.health/api/v1/patient/",
        headers: {
          Authorization: ctx.id,
        },
      },
      applyRes,
      getData
    );
  }, [fetchTasks]);

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className="container">
        <nav className={classes.breadcrumb}>
          <ul>
            <li className={classes["breadcrumb-item"]}>
              <Link to="/">{t("main")}</Link>
            </li>
            <li className={classes["breadcrumb-item active"]}>
              {t("profile")}
            </li>
          </ul>
        </nav>
      </div>

      <LabelForm
        edit={edit}
        setEdit={setEdit}
        saveEdit={saveEdit}
        setSaveEdit={setSaveEdit}
        profile={profile}
      />

      <InputForm
        edit={edit}
        setEdit={setEdit}
        saveEdit={saveEdit}
        setSaveEdit={setSaveEdit}
        profile={profile}
      />

      <p className="errorMessage">{error}</p>
    </>
  );
};

export default ProfileForm;
