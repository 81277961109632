import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
import { FaBars, FaTimes } from "react-icons/fa";
import { AiFillHome, AiOutlineDown } from "react-icons/ai";
import image from "../../assests/logo 1.png";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import classes from "./Navbar.module.css";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const Navbar = () => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const isLoggedIn = ctx.isLoggedIn;

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const Navref = useRef();
  const Popref = useRef();

  const showNavHandler = () => {
    Navref.current.classList.add("open");
    Popref.current.classList.add("open");
    document.body.classList.add("ovh");
  };

  const removePopupHandler = () => {
    Navref.current.classList.remove("open");
    Popref.current.classList.remove("open");
    document.body.classList.remove("ovh");
  };

  const search = useLocation();

  useEffect(() => {
    removePopupHandler();
  }, [search.pathname]);

  const logOutHandler = () => {
    fetch("https://backend.curecare.health/api/v1/patients/logout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: ctx.id,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          localStorage.clear();
          ctx.logout();
          ctx.navigate("/login");
        }
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed !";
            if (data && data.status && data.status.message) {
              errorMessage = data.status.message;
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {})
      .catch((err) => {
        setError(err.message);
      });
    ctx.logout();
  };

  if (isLoading) {
    return <div className="loading"></div>;
  }

  return (
    <header className={classes.header}>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div ref={Popref} onClick={removePopupHandler} className="popup"></div>
      <div className="container">
        <nav>
          <figure>
            <NavLink className={classes.figure} to="/">
              <AiFillHome className={classes.icon} fontSize={25} />
            </NavLink>
          </figure>
          <div className={classes.main}>
            <ul ref={Navref}>
              <div className={classes.navbar}>
                <li>
                  <NavLink className={classes.logo} to="/">
                    <img src={image} alt="logo" />
                  </NavLink>
                </li>
                <li className="drop">
                  <NavLink activeClassName={classes.active} to="/nurse">
                    {t("nurse")}
                    <AiOutlineDown className="svgDown" />
                  </NavLink>
                  <div className="down">
                    <li>
                      <Link to="/nurseSuggest">{t("nurseSuggest")}</Link>
                    </li>
                    <li>
                      <Link to="/latestOrder">{t("latestOrder")}</Link>
                    </li>
                    <li>
                      <Link to="/pricing">{t("price")}</Link>
                    </li>
                  </div>
                </li>
                <li>
                  <NavLink activeClassName={classes.active} to="/pharm">
                    {t("pharm")}
                  </NavLink>
                </li>
                {isLoggedIn && (
                  <li>
                    <NavLink activeClassName={classes.active} to="/profile">
                      {t("profile")}
                    </NavLink>
                  </li>
                )}
                <li>
                  <a
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=201200495521"
                    target="_blank"
                  >
                    {t("whats-app")}
                  </a>
                </li>
                <li>
                  <NavLink activeClassName={classes.active} to="/Privacy">
                    {t("privacy")}
                  </NavLink>
                </li>
              </div>
              <div className={classes.sign}>
                {!isLoggedIn && (
                  <li>
                    <NavLink activeClassName={classes.active} to="/login">
                      {t("login")}
                    </NavLink>
                  </li>
                )}
                {isLoggedIn && (
                  <li>
                    <button className={classes.logout} onClick={logOutHandler}>
                      {t("logout")}
                    </button>
                  </li>
                )}
              </div>
              <button onClick={removePopupHandler} className="close-btn">
                <FaTimes />
              </button>
            </ul>
            <button className="menu-btn" onClick={showNavHandler}>
              <FaBars />
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
