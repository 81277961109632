import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./FormControl";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import classes from "../NurseSuggestion.module.css";
import { BiMap } from "react-icons/bi";
import { RiPhoneLine } from "react-icons/ri";
import { MdContactPage } from "react-icons/md";

const FormikContainer = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();
  const ctx = useContext(AuthContext);

  const [radio, setRadio] = useState();

  const fetchStaticPage = async () => {
    const res = await fetch(
      "https://backend.curecare.health/api/v1/static_pages/curecare"
    );
    if (res.status === 401) {
      ctx.logout();
      localStorage.clear();
      useHistory.replace("/login");
    }
    const data = await res.json();
    setRadio(data);
  };

  useEffect(() => {
    fetchStaticPage();
  }, []);

  const radioOptions = radio?.map((item) => {
    return item?.question;
  });

  const checkOption = [
    { key: "ذكر", value: "male" },
    { key: "أنثي", value: "female" },
  ];

  const initialValues = {
    description: "",
    phone: "",
    address: "",
    age: "",
    checkOption: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string().required(t("wrong-number")).min(10, t("short-number")),
    address: Yup.string().required(t("filed-required")),
    description: Yup.string().required(t("filed-required")),
  });

  const applyRes = (res) => {
    if (res.ok) {
      history.push("/success");
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    values["content"] = [];
    radio.map((item, index) => {
      let options = item?.question;
      options?.values?.map((value) => {
        let selected = false;
        if (options?.type === "multiple") {
          selected = values.q[index].includes(value.name);
        } else {
          selected = value.name === values.q[index];
        }
        value.value = selected;
      });
      values["content"].push(options);
    });

    let myArry = values?.content?.map((item) => {
      return {
        question: {
          name: item?.name,
          values: item?.values?.map((el) => {
            return {
              name: el?.name,
              value: el?.value,
            };
          }),
        },
      };
    });

    sendTaskRequest(
      {
        url: `https://backend.curecare.health/api/v1/care_request?location=${
          ctx?.coordinates?.lat + "," + ctx?.coordinates?.lng
        }`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: ctx.id,
        },
        body: {
          care_request: {
            address: values.address,
            description: values.description,
            phone: values.phone,
            gender: values.checkOption,
            age: values.age,
            content: myArry,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    <div className="loading"></div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}>
      {(formik) => (
        <Form>
          <div className={classes.control}>
            <FormikControl
              control="textarea"
              placeholder={t("details")}
              name="description"
              id="description"
              cols="30"
              rows="10"
            />
          </div>

          <div className={classes.controls}>
            <div className={classes.expand}>
              <div className={classes.control}>
                <BiMap />
                <FormikControl
                  control="input"
                  type="text"
                  name="address"
                  id="address"
                  placeholder={t("address")}
                />
              </div>
            </div>

            <div className={classes.expand}>
              <div className={classes.control}>
                <RiPhoneLine />
                <FormikControl
                  control="input"
                  type="number"
                  min="10"
                  name="phone"
                  id="phone"
                  placeholder={t("phone")}
                />
              </div>
            </div>
          </div>

          <div className={classes.control}>
            <FormikControl
              control="radio"
              name="options"
              options={radioOptions}
            />
          </div>

          <p className={classes["prop-name"]}>{t("age")}</p>
          <div className={classes.control}>
            <div className={classes.age}>
              <MdContactPage />
              <FormikControl
                control="input"
                type="number"
                min="1"
                name="age"
                id="age"
                placeholder={t("age")}
              />
            </div>
          </div>

          <div className={classes.control}>
            <FormikControl
              control="check"
              name="checkOption"
              options={checkOption}
            />
          </div>

          <div className={classes.action}>
            <button type="submit" className={classes.btn}>
              {t("rate")}
            </button>
          </div>

          <p className="errorMessage">{error}</p>
        </Form>
      )}
    </Formik>
  );
};

export default FormikContainer;
