import React, { useState, useEffect, useContext } from "react";
import Medicien from "../medicienPage/Medicien";
import Adress from "../addressPage/Adress";
import Pharmacy from "../pharmacyPage/Pharmacy";
import Success from "../successPage/Success";
import { Link } from "react-router-dom";
import classes from "./Pharm.module.css";
import Geocode from "react-geocode";
import { RiMedicineBottleFill } from "react-icons/ri";
import { MdLocalPharmacy } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../store/auth-context";
import Helmet from "react-helmet";

const Pharm = () => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  Geocode.setLocationType("ROOFTOP");
  Geocode.setApiKey("AIzaSyCCN1SPvf12IviIBDa_akmbdKq48Kfy8Pk");
  const [address, setAddress] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    Geocode.fromLatLng(ctx?.coordinates?.lat, ctx?.coordinates?.lng).then(
      (response) => {
        const addressName = response?.results[0]?.formatted_address;
        setAddress(addressName);
        setFormData({ ...formData, address: addressName });
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const [formData, setFormData] = useState({
    search: "",
    address: address,
    order: "",
    image: "",
    phone: "",
  });

  const setAddressValue = (value) => {
    setFormData({ ...formData, address: value });
    setAddress(value);
  };

  const FormTitle = [
    <nav className={classes.breadcrumb}>
      <ul>
        <li className={classes["breadcrumb-item"]}>
          <Link to="/">{t("main")}</Link>
        </li>
        <li className={classes["breadcrumb-item active"]}>{t("pharm")}</li>
      </ul>
    </nav>,
    <nav className={classes.breadcrumb}>
      <ul>
        <li className={classes["breadcrumb-item"]}>
          <Link to="/">{t("main")}</Link>
        </li>
        <li className={classes["breadcrumb-item active"]}>
          {t("add-address")}
        </li>
      </ul>
    </nav>,
    <nav className={classes.breadcrumb}>
      <ul>
        <li className={classes["breadcrumb-item"]}>
          <Link to="/">{t("main")}</Link>
        </li>
        <li className={classes["breadcrumb-item active"]}>
          {t("search-pharm")}
        </li>
      </ul>
    </nav>,
  ];

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Medicien
          setPage={setPage}
          page={page}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 1) {
      return (
        <Adress
          formData={formData}
          setFormData={setFormData}
          address={address}
          setAddressValue={setAddressValue}
          setPage={setPage}
          page={page}
        />
      );
    } else if (page === 2) {
      return (
        <Pharmacy
          formData={formData}
          setFormData={setFormData}
          page={page}
          setPage={setPage}
        />
      );
    } else {
      return <Success />;
    }
  };

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      {ctx.isLoggedIn ? (
        <div className={classes.form}>
          {page !== 3 && (
            <div className={classes.progressbar}>
              <div className="container">
                <div className={classes.steps}>
                  <div className={classes.divid}>
                    <div
                      className={classes.medicinebottle}
                      style={{
                        borderColor: "#02B0B1",
                      }}>
                      <RiMedicineBottleFill
                        className={classes.icon}
                        style={{
                          color: "#02B0B1",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        color: "#02B0B1",
                      }}>
                      {t("add-medicien")}
                    </p>
                  </div>
                  <div className={classes.divid}>
                    <div
                      className={classes.localpharmacy}
                      style={{
                        borderColor: page === 0 ? "#eaeeed" : "#02B0B1",
                      }}>
                      <MdLocalPharmacy
                        className={classes.icon}
                        style={{
                          color: page === 0 ? "#eaeeed" : "#02B0B1",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        color: page === 0 ? "#eaeeed" : "#02B0B1",
                      }}>
                      {t("add-address")}
                    </p>
                  </div>
                  <div className={classes.divid}>
                    <div
                      className={classes.mapmarker}
                      style={{
                        borderColor: page === 2 ? "#02B0B1" : "#eaeeed",
                      }}>
                      <FaMapMarkerAlt
                        className={classes.icon}
                        style={{
                          color: page === 2 ? "#02B0B1" : "#eaeeed",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        color: page === 2 ? "#02B0B1" : "#eaeeed",
                      }}>
                      {t("choose-pharm")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={classes["form-container"]}>
            <div className={classes.header}>{FormTitle[page]}</div>
            <div className={classes.body}>
              <div className="container">
                <div className={classes.page}>{PageDisplay()}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.center}>
          <div className="container">
            <div className={classes.newlink}>
              <h2>{t("go-sign")}</h2>
              <button>
                <Link className={classes.linkto} to="/login">
                  {t("sign")}
                </Link>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pharm;
