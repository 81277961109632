import React from "react";
import { Link } from "react-router-dom";
import image from "../../../assests/Vector1.png";
import classes from "./Success.module.css";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const Success = () => {
  const [t, i18n] = useTranslation();

  return (
    <section>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className={classes.image}>
        <img src={image} alt="success" />
        <h3 className={classes.message}>{t("success-message")} </h3>
      </div>
      <button className={classes.btn}>
        <Link className={classes.link} to="/">
          {t("back-home")}
        </Link>
      </button>
    </section>
  );
};

export default Success;
