import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext({
  id: "",
  token: "",
  isLoggedIn: false,
  login: (id) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const history = useHistory();
  const [coordinates, setCoordinates] = useState({});

  useEffect(() => {
    navigator.geolocation.watchPosition(
      function (position) {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      function (error) {}
    );
  }, []);

  const intialToken = localStorage.getItem("token");
  const [id, setId] = useState(intialToken);
  const userIsLoggedIn = !!id;

  const loginHandler = (id) => {
    setId(id);
    localStorage.setItem("token", id);
  };
  const logoutHandler = () => {
    setId(null);
    localStorage.removeItem("token");
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        //place your reentry code
        setId(null);
        localStorage.clear();
        history.replace("/login");
      }
      return error;
    }
  );

  const contextValue = {
    id: id,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    coordinates: coordinates,
    setCoordinates: setCoordinates,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
