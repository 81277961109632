import React from "react";
import TextError from "../TextError";
import { Field, ErrorMessage } from "formik";
import classes from "../NurseSuggestion.module.css";
import { useTranslation } from "react-i18next";

const Check = (props) => {
  const { label, name, options, ...rest } = props;
  const [t, i18n] = useTranslation();

  return (
    <div className={classes["radio-item"]}>
      <p className={classes["prop-name"]}>{t("gender")}</p>
      <div className={classes["contnet-center"]}>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <div className={classes.item} key={option.key}>
                  <label>
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      value={option.value}
                    />
                    <span className={classes.choose}>{option?.key}</span>
                  </label>
                </div>
              );
            });
          }}
        </Field>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
};

export default Check;
