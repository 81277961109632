import React, { useContext } from "react";

import * as Yup from "yup";
import TextError from "../login/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../login/Validate.css";

import classes from "../login/Login.module.css";
import image from "../../../assests/Rectangle 37.png";

import { RiPhoneLine, RiLockPasswordLine } from "react-icons/ri";
import { BiUser, BiEnvelope, BiMap } from "react-icons/bi";
import Loginface from "../loginFacebookPage/Loginface";
import AuthContext from "../../../store/auth-context";
import { Link } from "react-router-dom";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

const SignUpPage = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    address: "",
    password: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, t("too-short"))
      .max(50, t("too_long"))
      .required(t("filed-required")),
    phone: Yup.string().required(t("wrong-number")).min(10, t("short-number")),
    email: Yup.string().email(t("wrong-format")).required(t("filed-required")),
    address: Yup.string()
      .min(2, t("too-short"))
      .max(50, t("too_long"))
      .required(t("filed-required")),
    password: Yup.string()
      .required(t("enter-password"))
      .min(7, t("weak-password"))
      .matches(/[a-zA-Z]/, t("char-password")),
  });

  const applyRes = (res) => {
    ctx.login(res.headers.get("authorization"));
    if (res.ok) {
      history.replace("/login");
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: "https://backend.curecare.health/api/v1/patients/signup",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          patient: {
            name: values.name,
            email: values.email,
            password: values.password,
            address: values.address,
            phone: values.phone,
            location: ctx.coordinates,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}>
        <div className={classes.controls}>
          <Form className={classes.form}>
            <div className="container">
              <div className={classes.control}>
                <h2>{t("welcome")}</h2>
                <p>{t("best-serve")}</p>
                <div className={classes["form-control"]}>
                  <label htmlFor="name"></label>
                  <BiUser />
                  <Field
                    type="text"
                    maxLength="30"
                    placeholder={t("name")}
                    name="name"
                    id="name">
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="name"></label>
                        <input
                          type="text"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("name")}
                          name="name"
                          id="name"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="name" component={TextError} />
                <div className={classes["form-control"]}>
                  <label htmlFor="phone"></label>
                  <RiPhoneLine />
                  <Field
                    type="number"
                    min="1"
                    name="phone"
                    id="phone"
                    placeholder={t("phone")}>
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="phone"></label>
                        <input
                          type="number"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("phone")}
                          name="phone"
                          id="phone"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="phone" component={TextError} />
                <div className={classes["form-control"]}>
                  <label htmlFor="email"></label>
                  <BiEnvelope />
                  <Field
                    type="email"
                    placeholder={t("email")}
                    name="email"
                    id="email">
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="email"></label>
                        <input
                          type="email"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("email")}
                          name="email"
                          id="email"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="email" component={TextError} />
                <div className={classes["form-control"]}>
                  <label htmlFor="address"></label>
                  <BiMap />
                  <Field
                    type="text"
                    placeholder={t("address")}
                    name="address"
                    id="address">
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="address"></label>
                        <input
                          type="text"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("address")}
                          name="address"
                          id="address"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="address" component={TextError} />
                <div className={classes["form-control"]}>
                  <label htmlFor="password"></label>
                  <RiLockPasswordLine />
                  <Field
                    autoComplete="on"
                    type="password"
                    placeholder={t("password")}
                    name="password"
                    id="password">
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="password"></label>
                        <input
                          type="password"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("password")}
                          name="password"
                          id="password"
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="password" component={TextError} />
                <button type="submit" className={classes.btn}>
                  {t("signup")}
                </button>
                <Loginface />
                <Link to="/login" type="button" className={classes.toggle}>
                  {t("have-account")}
                  <span> {t("sign")} </span>
                </Link>
              </div>
            </div>
            <p className="errorMessage">{error}</p>
          </Form>
          <div className={classes.image}>
            <div className={classes.overlay}></div>
            <img src={image} alt="site" />
          </div>
        </div>
      </Formik>
    </>
  );
};

export default SignUpPage;
