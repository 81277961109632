import React from "react";
import Helmet from "react-helmet";
import Navbar from "./Navbar";
import Footer from "../footerPage/Footer";

const Layout = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Navbar />
      <div className="wrapper">
        <main>{props.children}</main>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
