import React, { useContext, useEffect, useState } from "react";
import classes from "./Pharmacy.module.css";
import * as geolib from "geolib";
import AuthContext from "../../../store/auth-context";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

const Pharmacy = ({ formData, setFormData, page, setPage }) => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const [pharm, setPharm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wrong, setWrong] = useState(null);
  const { loading, error, sendRequest: fetchTasks } = useHttp();

  const submitHandler = () => {
    const data = new FormData();
    data.append("address", formData.address);
    data.append("pharmacy_id", formData.search);
    if (formData.image !== "") {
      data.append("attachment", formData.image);
    }
    data.append("content", formData.order);
    data.append("phone_number", formData.phone);
    setIsLoading(true);
    setWrong(null);
    fetch(
      `https://backend.curecare.health/api/v1/pharmacies/medicine_request?phone=${formData.phone}`,
      {
        method: "POST",
        headers: {
          Authorization: ctx.id,
        },
        body: data,
      }
    )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          ctx.logout();
          localStorage.clear();
          useHistory.replace("/login");
        }
        if (res.ok) {
          setPage(page + 1);
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed !";
            if (data && data.status && data.status.message) {
              errorMessage = data.status.message;
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {})
      .catch((err) => {
        setWrong(err.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    const applyRes = (res) => {};

    const getData = (data) => {
      setPharm(data.data);
    };

    fetchTasks(
      {
        url: `https://backend.curecare.health/api/v1/pharmacies?page=1&location=${
          ctx?.coordinates?.lat + "," + ctx?.coordinates?.lng
        }`,
        headers: {
          "Content-Type": "application/json",
          Authorization: ctx.id,
        },
      },
      applyRes,
      getData
    );
  }, [fetchTasks]);

  if (loading) {
    return <div className="loading"></div>;
  }

  if (isLoading) {
    return <div className="loading"></div>;
  }

  return (
    <div className={classes.search}>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className="container">
        <h2>{t("pharm")}</h2>
        <section>
          <p className={classes.title}>{t("near-pharm")}</p>
          {pharm.map((item) => {
            return (
              <div className={classes["radio-item"]} key={item.id}>
                <label>
                  <input
                    type="radio"
                    name="pharm"
                    onClick={() =>
                      setFormData({ ...formData, search: item?.id })
                    }
                  />
                  <span className={classes.choose}>
                    <div className={classes.nearPharm}>
                      <div className={classes.image}>
                        <img
                          src={item?.attributes?.profile_image}
                          alt={item?.attributes?.name}
                        />
                      </div>
                      <div className={classes.discription}>
                        <h3 className={classes.pharmName}>
                          {item?.attributes?.name}
                        </h3>
                        <h4 className={classes.distance}>
                          <span>{t("far-of")} </span>
                          {item?.attributes?.location?.x &&
                          item?.attributes?.location?.y &&
                          ctx?.coordinates?.lat &&
                          ctx?.coordinates?.lng
                            ? geolib
                                .getDistance(
                                  {
                                    latitude: ctx?.coordinates?.lat,
                                    longitude: ctx?.coordinates?.lng,
                                  },
                                  {
                                    latitude: item?.attributes?.location?.x,
                                    longitude: item?.attributes?.location?.y,
                                  }
                                )
                                .toFixed(2) / 1000
                            : ""}
                          <span> {t("km")} </span>
                        </h4>
                      </div>
                    </div>
                  </span>
                </label>
              </div>
            );
          })}
        </section>

        <div className={classes.next}>
          <button className={classes.nextbtn} onClick={submitHandler}>
            {t("submit")}
          </button>
        </div>

        <p className="errorMessage">{error}</p>
        <p className="errorMessage">{wrong}</p>
      </div>
    </div>
  );
};

export default Pharmacy;
