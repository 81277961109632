import React from "react";
import Check from "./Check";
import Input from "./Input";
import RadioButton from "./RadioButton";
import Textarea from "./Textarea";

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "check":
      return <Check {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
