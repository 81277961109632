import React, { useState, useCallback, useEffect, useContext } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import Header from "../headerPage/Header";
import Map from "../mapPage/Map";
import Nurse from "../nursePage/Nurse";

const Home = () => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState("");
  const [special, setSpecial] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [id, setId] = useState();
  const ctx = useContext(AuthContext);

  const fetchData = useCallback(async (page, gender, search, special) => {
    let filterPerPage;
    if (page === undefined || page === null || page === "") {
      filterPerPage = 1;
    } else {
      filterPerPage = page;
    }

    let filterByGender;
    if (gender === undefined || gender === null || gender === "") {
      filterByGender = "";
    } else {
      filterByGender = gender;
    }

    let filterBySearch;
    if (search === undefined || search === null || search === "") {
      filterBySearch = "";
    } else {
      filterBySearch = search;
    }

    let filterBySpecialID;
    if (special === undefined || special === null || special === "") {
      filterBySpecialID = "";
    } else {
      filterBySpecialID = special;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://backend.curecare.health/api/v1/nurses?page=${filterPerPage}&gender=${filterByGender}&name=${filterBySearch}&specialty_id=${filterBySpecialID}`
      );
      if (response.status === 401) {
        ctx.logout();
        localStorage.clear();
        useHistory.replace("/login");
      }
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      setList(data.data);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timer);
  }, [page]);

  if (loading) {
    return <div className="loading"></div>;
  }
  return (
    <div>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Map list={list} error={error} id={id} setId={setId} />

      <Header
        list={list}
        page={page}
        setGender={setGender}
        gender={gender}
        search={search}
        setSearch={setSearch}
        fetchData={fetchData}
        special={special}
        setSpecial={setSpecial}
        error={error}
      />
      <Nurse
        list={list}
        page={page}
        setPage={setPage}
        error={error}
        id={id}
        setId={setId}
      />
    </div>
  );
};

export default Home;
