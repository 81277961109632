import React from "react";
import Helmet from "react-helmet";
import classes from "./Privacy.module.css";
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    direction: 'rtl',
    lineHeight: '1.6',
    margin: '20px',
  },
  header: {
    fontSize: '24px',
    marginBottom: '16px',
  },
  subHeader: {
    fontSize: '20px',
    marginTop: '16px',
    marginBottom: '10px',
  },
  paragraph: {
    margin: '10px 0',
  },
  info: {
    marginTop: '10px',
  },
  link: {
    color: '#0066cc',
  },
};
const Privacy = () => {
  return (
<div className="privacy-policy-container">


<p>كيوركير هي شركة وساطة طبية لتقديم خدمة الرعاية الصحية عن طريق تكنولوجيا التحول الرقمي...</p>

<section>
  <h2>1- أنواع البيانات التي يتم جمعها</h2>
  < p>1 البيانات األساسية -:
هي بيانات يتم جمعها طواعية من المستخدمين اثناء التسجيل او استخدام التطبيق / الموقع على سبيل الحصر
بيانات يتم جمعها أثناء التسجيل
) البريد اإللكتروني - االسم - السن - النوع - رقم التليفون - العنوان - بيانات عضوية كارت التأمين الطبي (
بيانات يتم جمعها أثناء استخدام التطبيق على سبيل المثال وليس الحصر
) الموقع الجغرافي - التاريخ الطبي للمريض من أسماء األدوية وطرق تنفيذها ومواعيدها وتشخيص الحالة واألمراض المزمنة و أنواع
التحاليل واإلشاعات المطلوبة (
1 2- البيانات التي يتم جمعها تلقائيا
هذه البيانات ال يتم جمعها إال بعد الحصول على موافقة من المستخدمين
- البيانات المؤقتة وهي بيانات يتم جمعها أثناء جلسة االستخدام وتتطلب الحصول على إذن من المستخدمين وبدونها يصعب
استخدام بعض خدمات التطبيق ومن أمثلة تلك البيانات على سبيل المثال وليس الحصر
) الموقع الجغرافي - الصور - اإلشعارات (
- بيانات االستخدام
عند الوصول إلى الخدمة عن طريق جهاز محمول أو من خالله ، قد نقوم بتجميع بعض المعلومات تلقائًيا ، بما في ذلك ، على سبيل المثال ال الحصر
، نوع الجهاز المحمول الذي تستخدمه ومعرف جهازك المحمول الفريد وعنوان IP لجهازك المحمول و نظام التشغيل ، ونوع متصفح اإلنترنت عبر
الهاتف المحمول الذي تستخدمه
1 3- بيانات يتم جمعها عن طريق أطراف ثالثة
وهي أطراف يتم االعتماد عليها في عملية التسجيل و تستخدم الكوكيز لجمع بيانات المستخدمين وهي تخضع لسياسات الخصوصية
الخاصة بها .
1 4- بيانات تعريف االرتباط ) الكوكيز (
نحن نستخدم ملفات تعريف االرتباط وتقنيات التتبع المماثلة لتتبع النشاط على خدمتنا واالحتفاظ بمعلومات معينة.
فا فريًدا مجهول الهوية. يتم إرسال ملفات تعريف
ملفات تعريف االرتباط هي ملفات تحتوي على كمية صغيرة من البيانات التي قد تتضمن معرً
االرتباط إلى متصفحك من موقع ويب وتخزينها على جهازك. تقنيات التتبع المستخدمة هي أي ًضا إشارات وعالمات ومخطوطات لجمع المعلومات
وتتبعها وتحسين خدماتنا وتحليلها.
يمكنك توجيه المستعرض الخاص بك إلى رفض كافة ملفات تعريف االرتباط أو اإلشارة إلى وقت إرسال ملف تعريف االرتباط. ومع ذلك ، إذا لم تقبل
ملفات تعريف االرتباط ، فقد ال تتمكن من استخدام بعض أجزاء من خدمتنا.
أمثلة لملفات تعريف االرتباط التي نستخدمها:
● ملفات تعريف ارتباط الجلسة. نحن نستخدم ملفات تعريف االرتباط الخاصة بالجلسات لتشغيل خدمتنا.
● ملفات تعريف االرتباط المفضلة. نحن نستخدم ملفات تعريف االرتباط المفضلة لتذكر تفضيالتك واإلعدادات المختلفة.
● ملفات تعريف االرتباط األمنية. نحن نستخدم ملفات تعريف ارتباط األمان ألغراض أمني</p>
</section>

<section>
  <h2>2- الغرض من جمع وتحليل البيانات</h2>
  <p>لا يتم جمع أو تحليل أي بيانات قبل موافقة متلقي الخدمة وتتعهد كيوركير
أن ال يتم استخدام البيانات إال في األغراض المنصوص عليها في هذا البند
ويكون الغرض من االستخدام حسب نوع البيانات التي يتم جمعها
2 1- البيانات األساسية : هي بيانات يتم جمعها بغرض تقديم الخدمة من خالل التطبيق وبدونها يصعب استخدام بعض خدمات التطبيق على سبيل
الحصر
● االسم / السن / رقم التليفون / العنوان / البريد اإللكتروني
هي بيانات يتم جمعها بغرض التسجيل وإنشاء حساب داخل التطبيق
● بيانات عضوية كارت التأمين الطبي يتم جمعها بغرض توفير مقدمي الخدمات الطبية المشتركين مع شركة التأمين التابع لها
● أسماء األدوية وطرق تنفيذها ومواعيدها وتشخيص الحالة واألمراض المزمنة و أنواع التحاليل واإلشاعات المطلوبة
● يتم جمعها بغرض نقلها لمقدمي الخدمات الطبية الستكمال طلب العميل وتقديم الخدمة له
● ويتم حفظها وتجميعها إلنشاء ملف يحتوي على التاريخ الطبي للمستخدم
● يتم تحديد الموقع الجغرافي لتوفير مقدمي الخدمات الطبية في النطاق األقرب لك
● يتم تجميع الصور بغرض استكمال وتوضيح الطلب ونقله إلى مقدم الخدمة
● السماح بالموافقة على اإلشعارات
لتقديم خدمة التذكير والتنبيه بمواعيد جرعات األدوية
وتقديم النصائح الطبية المستمرة والعروض للمستخدم
2 2- البيانات التي يتم جمعها تلقائيا وبيانات تعريف االرتباط :
● تقديم أفضل تجربة تصفح للمستخدم
● معرفة تفضيالت وسلوك المستخدمين لتوفير تجربة أكثر سهولة ويسر
● تحليل البيانات وتبويبها لدعم اتخاذ القرارات اإلدارية والتسويقية
● معرفة المشاكل التقنية ومعالجتها
2 3- البيانات التي يتم جمعها بواسطة األطراف الثالثة
تكون بغرض انشاء الحسابات وتسجيل الدخول
مثل منصة الفيس بوك ، وجوجل</p>
</section>

<section>
  <h2>3- مشاركة البيانات مع أطراف ثالثة</h2>
  <p>في االصل يتم االطالع على البيانات من مقدمي الخدمات الطبية والموظفين داخل الشركة لتسيير العمل
وال يتم مشاركة البيانات مع أطراف ثالثة إطالقا إال في حالة تلبية االلتزامات القانونية ووجود أمر قضائي</p>
</section>
<section>
  <h2>4- تأمين البيانات والمعلومات</h2>
  <p>على الرغم من أن نقل المعلومات عبر اإلنترنت ليس آمنا تما ًما لكن تبذل كيوكير قصارى جهدها لحماية بيانات المستخدمين</p>
  <p>لذلك تكون البيانات والمعلومات التي يقوم الموقع بتجميعها يتم معالجتها وتخزينها بشكل آمن ويقتصر الوصول إلى هذه البيانات على األطراف
المنصوص عليها في هذه السياسة</p>
</section>
<section>
  <h2>5- سيطرة المستخدم على بياناته</h2>
  <p>تحفظ كيوركير للمستخدم الحق الكامل في السيطرة على بياناته عن طريق
الحق في إلغاء االشتراك وحذف الحساب
وتتعهد الشركة بتفعيل خاصية حذف الحساب تلقائيا وبعدها يتم محو بيانات المستخدم في الحال
طريق حذف الحساب
● الخطوة االولي : الدخول للحساب عن طريق التطبيق
● الخطوة الثانية : الدخول علي المزيد
● الخطوة الثالثة : الضغط على حذف الحساب
او التواصل على الميل التالي طلب الحذف
deletion.account@curecare.health</p>
</section>
<section>
  <h2>6- اإلبالغ عن تعديل أو تحديث سياسة الخصوصية</h2>
  <p>تخضع سياسة الخصوصية دائما للتطوير والتحديث لتتماشى مع تطوير البرمجيات والخدمات لذلك تلتزم الشركة بإبالغ كافة المستخدمين عن طريق
البريد اإللكتروني بأي تعديل أو تحديث يخص الخصوصية
ويكون الحق للمستخدم الموافقة أو رفض التعديل وطلب حذف الحساب كما هو موضوع في السياسة</p>
</section>
<section>
  <h2>7- سقوط الحق في استخدام واالحتفاظ بالبيانات والمعلومات</h2>
  <p> طلب المستخدم إلغاء اشتراكه.
● طلب ال ُمستخدم محو بياناته ومعلوماته.
● وقف الخدمة التي ُجمعت من أجلها البيانات والمعلومات.
● انتهاء الغرض الذي ُجمعت من أجله البيانات.
● عدم موافقة المستخدم على التعديالت أو التغيرات التي تطرأ على سياسة الخصوصية، وفي هذه الحالة ال يقوم الموقع أو
التطبيق باستخدام أ ٍّي من البيانات والمعلومات التي ُجمعت قبل التعديل/التغيير</p>
</section>
<section>
  <h2>8- اإلبالغ عن البيانات والتسريبات</h2>
  <p>تلتزم الشركة باإلبالغ عن أي تسريب للبيانات أو أي اختراق أمني للمعلومات التي تخص المستخدمين ومدى تأثير الضرر
الناتج عن هذا</p>
</section>

<div style={styles.container}>
      <h2 style={styles.header}>معلومات التصال بكيوركير</h2>
      <p style={styles.info}><strong>العنوان:</strong> برج الأبرار العصافرة بحري, الاسكندرية, مصر</p>
      <p style={styles.info}><strong>البريد الإلكتروني:</strong> <a href="mailto:info@curecare.health" style={styles.link}>info@curecare.health</a></p>
      <p style={styles.info}><strong>رقم الهاتف:</strong> -0</p>
    </div>

    <h1 style={styles.header}>CureCare Privacy Policy</h1>
      <p style={styles.paragraph}>
        CureCare is a medical brokerage company that provides healthcare services through digital transformation technology. It is a limited liability company based in Burj Al-Abrar, El Asafra, Alexandria, Egypt. CureCare recognizes the importance of personal data privacy for users. Therefore, in these policies, we outline the types of data collected, methods of collection, how it is used and processed, how information is protected, storage methods, and clarification of user rights to delete or modify their data.
      </p>

      <h2 style={styles.subHeader}>1. Types of Data Collected:</h2>

      <h3 style={styles.subHeader}>1.1 Basic Data:</h3>
      <p style={styles.paragraph}>
        These are voluntarily collected data from users during registration or use of the application/website, including but not limited to:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Data collected during registration (email, name, age, gender, phone number, address, medical insurance membership data).</li>
        <li style={styles.listItem}>Data collected during app usage, including but not limited to (geographic location, patient's medical history including medication names, administration methods, schedules, diagnosis, chronic conditions, types of tests, and required imaging).</li>
      </ul>

      <h2 style={styles.subHeader}>1-2 The Data Collected Automatically:</h2>
      <p style={styles.paragraph}>
        This includes data that requires user consent prior to collection, such as temporary data gathered during user sessions, which necessitates user permission for use. Failure to obtain such consent may hinder the utilization of certain application services, including geolocation, images, and notifications. Usage data is automatically gathered when accessing the service via a mobile device, including the type of mobile device, its unique identifier, IP address, operating system, and internet browser type utilized on the mobile phone.
      </p>

      <h2 style={styles.subHeader}>1-3 Data Collected Through Third Parties:</h2>
      <p style={styles.paragraph}>
        Data collection facilitated by third parties relies on entities that are integral to the registration process and use cookies to gather user data, adhering to their own privacy policies.
      </p>

      <h2 style={styles.subHeader}>1-4 Cookies and Similar Tracking Technologies:</h2>
      <p style={styles.paragraph}>
        We utilize cookies and similar tracking technologies to monitor activity on our service and retain specific information, which include session cookies, preference cookies, and security cookies, essential for operational, preferences remembering, and security purposes, respectively.
      </p>

      <h2 style={styles.subHeader}>2- The Purpose of Data Collection and Analysis:</h2>
      <p style={styles.paragraph}>
        The purpose of data collection and analysis is not initiated until the recipient of the service agrees, and CureCare commits not to use the data except for the purposes specified in this section. The purpose of use is determined based on the type of data collected.
      </p>

      <h3 style={styles.subHeader}>2-1 Basic Data:</h3>
      <p style={styles.paragraph}>
        This refers to information collected for the purpose of providing a service through the application, and without it, it would be difficult to use some of the application's services, to name a few: name, age, phone number, address, email. This data is collected for registration and account creation within the application. Membership data for medical insurance cards is collected to provide medical service providers who are affiliated with the insurance company. Information such as drug names, administration methods, schedules, diagnoses, chronic conditions, types of tests, and required imaging are collected to be transferred to medical service providers to complete the client's request and provide the service. This information is stored and compiled to create a file containing the user's medical history. Geographical location is determined to provide medical service providers closest to the user. Images are collected to complete and clarify the request and transfer it to the service provider. Consent is given for notifications to provide reminders and alerts for medication dosages and to offer continuous medical advice and promotions to the user.
      </p>

      <h3 style={styles.subHeader}>2-2 Automatically Collected Data and Cookies:</h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>Providing users with an enhanced browsing experience.</li>
        <li style={styles.listItem}>Understanding user preferences and behaviors to facilitate a smoother experience.</li>
        <li style={styles.listItem}>Analyzing and categorizing data to support administrative and marketing decision-making.</li>
        <li style={styles.listItem}>Identifying and resolving technical issues.</li>
      </ul>

      <h3 style={styles.subHeader}>2-3 Data Collected by Third Parties:</h3>
      <p style={styles.paragraph}>
        Data collected by third parties, such as Facebook and Google, is gathered for the purpose of creating accounts and logging in.
      </p>

      {/* Continuing the Privacy Policy Content */}
      <h2 style={styles.subHeader}>3- Data Sharing with Third Parties:</h2>
      <p style={styles.paragraph}>
      Data sharing with third parties is typically limited to healthcare
providers and internal company employees for operational purposes.
Data is not shared with third parties unless required by legal obligations
or a court order.
      </p>

      <h2 style={styles.subHeader}>4- Data and Information Security:</h2>
      <p style={styles.paragraph}>
      Data and Information Security
While transferring information online is not completely secure, curecare
makes every effort to protect user data. Therefore, the data and
information collected by the website are processed and stored
securely, with access to this data limited to the parties specified in this
policy.      </p>

      <h2 style={styles.subHeader}>5- User Control Over Data:</h2>
      <p style={styles.paragraph}>
      The user's control over their data is ensured by CureCare, granting
them full rights to manage their information through the ability to
unsubscribe and delete their account.
The company commits to activating an automatic account deletion
feature, promptly erasing user data upon account deletion.
The initial step involves
logging into the account via the application, followed by accessing
additional options and selecting the account deletion function.
Alternatively, users can follow a provided link for account deletion or
contact the specified email address for deletion requests at
deletion.account@curecare.health      </p>

      <h2 style={styles.subHeader}>6- Modifications and Updates to the Privacy Policy:</h2>
      <p style={styles.paragraph}>
      The reporting of any modifications or updates to the privacy policy is
a continuous process aimed at aligning the policy with the
advancements in software and services.
Therefore, the company is committed to informing all users via email
of any privacy-related modifications or updates.
Users have the right to either consent to or reject the modification and
request the deletion of their account, as outlined in the policy.      </p>

      <h2 style={styles.subHeader}>7- Loss of Rights in Data Usage, Retention, and Storage:</h2>
      <p style={styles.paragraph}>
      - The loss of rights in using, retaining, and storing data and
information.
The user requested to unsubscribe.
The user requested to delete their data
and information.
The service that collected the data and
information has been stopped.
The purpose for which the data was
collected has ended.
If the user does not agree to the modifications or changes made to the
privacy policy, in this case, the website or application will not use any of
the data and information collected before the modification/change.      </p>

      <h2 style={styles.subHeader}>8- Reporting Data Leaks or Security Breaches:</h2>
      <p style={styles.paragraph}>
      The protocol of the company includes reporting any data leaks or
security breaches that involve user information, along with assessing
the extent of the resulting damage.      </p>

      {/* Contact Information */}
      <h2 style={styles.subHeader}>Contact Information for CureCare:</h2>
      <p style={styles.paragraph}>Address: Abrar Tower, El Asafra Bahri, Alexandria, Egypt.</p>
      <p style={styles.paragraph}>
        Email: <a href="mailto:info@curecare.health" style={styles.link}>info@curecare.health</a>
      </p>
      <p style={styles.paragraph}>Phone number: +20 3 5576608</p>

</div>



  );
};

export default Privacy;
