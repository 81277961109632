import React from "react";
import { Link } from "react-router-dom";
import classes from "./NurseSuggestion.module.css";
import { useTranslation } from "react-i18next";

import Helmet from "react-helmet";
import FormikContainer from "./FormikForm/FormikContainer";

const NurseSuggestion = () => {
  const [t, i18n] = useTranslation();

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div>
        <div className={classes.suggest}>
          <div className={classes.nursesuggest}>
            <nav className={classes.breadcrumb}>
              <ul>
                <li className={classes["breadcrumb-item"]}>
                  <Link to="/">{t("main")}</Link>
                </li>
                <li className={classes["breadcrumb-item active"]}>
                  {t("homecare-order")}
                </li>
              </ul>
            </nav>
            <h2 className={classes.name}>{t("homecare-order")}</h2>
          </div>
          <div className="container">
            <FormikContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default NurseSuggestion;
