import React, { useContext } from "react";

import * as Yup from "yup";
import TextError from "../login/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../login/Validate.css";

import classes from "../login/Login.module.css";
import image from "../../../assests/Rectangle 37.png";

import { RiLockPasswordLine } from "react-icons/ri";
import { BiEnvelope } from "react-icons/bi";
import Loginface from "../loginFacebookPage/Loginface";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const LoginPage = () => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const history = useHistory();
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("wrong-format")).required(t("filed-required")),
    password: Yup.string()
      .required(t("enter-password"))
      .min(7, t("weak-password"))
      .matches(/[a-zA-Z]/, t("char-password")),
  });

  const applyRes = (res) => {
    ctx.login(res.headers.get("authorization"));
    if (res.ok) {
      history.replace("/");
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: "https://backend.curecare.health/api/v1/patients/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          patient: {
            email: values.email,
            password: values.password,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        <div className={classes.controls}>
          <Form className={classes.form}>
            <div className="container">
              <div className={classes.control}>
                <h2>{t("welcome-back")}</h2>
                <p>{t("best-serve")}</p>
                <div className={classes["form-control"]}>
                  <label htmlFor="email"></label>
                  <BiEnvelope />
                  <Field
                    type="email"
                    placeholder={t("email")}
                    name="email"
                    id="email"
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="email"></label>
                        <input
                          type="email"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("email")}
                          name="email"
                          id="email"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="email" component={TextError} />
                <div className={classes["form-control"]}>
                  <RiLockPasswordLine />
                  <label htmlFor="password"></label>
                  <Field
                    type="password"
                    placeholder={t("password")}
                    autoComplete="on"
                    name="password"
                    id="password"
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="password"></label>
                        <input
                          type="password"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("password")}
                          name="password"
                          id="password"
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="password" component={TextError} />
                <button type="submit" className={classes.btn}>
                  {t("signed-in")}
                </button>
                <Loginface />
                <Link
                  to="/forget-password?"
                  className={classes.forget}
                  type="button"
                >
                  {t("forget-password")}
                </Link>
                <Link to="/signup" type="button" className={classes.toggle}>
                  {t("no-account")}
                  <span> {t("new-account")} </span>
                </Link>
              </div>
            </div>
            <p className="errorMessage">{error}</p>
          </Form>

          <div className={classes.image}>
            <div className={classes.overlay}></div>
            <img src={image} alt="site" />
          </div>
        </div>
      </Formik>
    </>
  );
};

export default LoginPage;
