import React from "react";
import classes from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import instagram from "../../assests/instagram.svg";
import googleplay from "../../assests/googleplay.png";
import appstore from "../../assests/app-store.svg";
import facebook from "../../assests/facebook.jpeg";
import whatsapp from "../../assests/WhatsApp.svg";

const Footer = () => {
  const [t, i18n] = useTranslation();
  return (
    <footer>
      <div className={classes.footerDiv}>
        <div>
          <p> {t("follow-us-on")}</p>
          <div className={classes.footerIcons}>
            <a
              activeClassName={classes.active}
              href="https://www.facebook.com/curecarehealth?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="logo" className={classes.facebook} />
            </a>
            <a
              activeClassName={classes.active}
              href="https://www.instagram.com/curecareegy?igsh=MTh2ZHVqMmFjdmppdg=="
              rel="noreferrer"
              target="_blank"
            >
              <img src={instagram} alt="logo" className={classes.instagram} />
            </a>
            <a
              activeClassName={classes.active}
              href="https://wa.me/+0201200116304"
              rel="noreferrer"
              target="_blank"
            >
              <img src={whatsapp} alt="logo" className={classes.whatsapp} />
            </a>
          </div>
        </div>
        <div className={classes.poweredby}>Powered by Sea Dev</div>
        <div>
          <p> {t("download-our-app")}</p>
          <div className={classes.footerIcons}>
            <a
              activeClassName={classes.active}
              href="https://apps.apple.com/eg/app/cure-care/id1659503953"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appstore} alt="logo" className={classes.appstore} />
            </a>
            <a
              activeClassName={classes.active}
              href="https://play.google.com/store/apps/details?id=com.tamred"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleplay} alt="logo" className={classes.googleplay} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
