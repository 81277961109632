import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Rate.module.css";
import ReactStars from "react-rating-stars-component";
import AuthContext from "../../../store/auth-context";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Rate = ({ rate, setRate, saveRate, setSaveRate, item }) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();
  const ctx = useContext(AuthContext);

  const [sendData, setSendData] = useState({
    rate: 0,
    description: "",
    nurse_id: item?.id,
  });

  const rateHandler = () => {
    setSaveRate(false);
    setRate(true);
  };

  const ratingChanged = (newRating) => {
    setSendData({ ...sendData, rate: newRating });
  };

  const applyRes = (res) => {
    if (res.ok) {
      history.push("/success");
    }
  };

  const getData = (data) => {};

  const submitHandler = async () => {
    sendTaskRequest(
      {
        url: `https://backend.curecare.health/api/v1/reviews`,
        method: "POST",
        headers: {
          Authorization: ctx.id,
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          review: {
            rate: sendData.rate,
            description: sendData.description,
          },
          nurse_id: Number(item?.id),
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div>
      {saveRate && (
        <div className="container">
          <div>
            <nav className={classes.breadcrumb}>
              <ul>
                <li className={classes["breadcrumb-item"]}>
                  <Link to="/">{t("main")}</Link>
                </li>
                <li className={classes["breadcrumb-item"]}>
                  <Link onClick={rateHandler} to="/latestOrder">
                    {t("latestOrder")}
                  </Link>
                </li>
                <li className={classes["breadcrumb-item active"]}>
                  {t("rate")}
                </li>
              </ul>
            </nav>
          </div>
          <section>
            <div className={classes.last}>
              <div className={classes.profile}>
                <div className={classes.image}>
                  <img
                    src={item?.attributes?.profile_image}
                    alt={item?.attributes?.name}
                  />
                </div>
                <div className={classes.info}>
                  <h4 className={classes.name}>{item?.attributes?.name}</h4>
                  <p className={classes.type}>
                    {item?.attributes?.specialty_name}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className={classes.stars}>
              <ReactStars
                value={sendData.rate}
                onChange={ratingChanged}
                count={5}
                size={24}
                activeColor="#ffd700"
              />
            </div>
            <textarea
              placeholder={t("enter-rate")}
              name=""
              id=""
              cols="30"
              rows="10"
              value={sendData.description}
              onChange={(e) =>
                setSendData({
                  ...sendData,
                  description: e.target.value,
                })
              }></textarea>

            <div className={classes.next}>
              <button className={classes.nextbtn} onClick={submitHandler}>
                {t("rate")}
              </button>
            </div>
            <p className="errorMessage">{error}</p>
          </section>
        </div>
      )}
    </div>
  );
};

export default Rate;
