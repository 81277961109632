import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import classes from "./Pricing.module.css";
import { useTranslation } from "react-i18next";
import useHttp from "../../hook/use-fetch";
import Helmet from "react-helmet";

const Pricing = ({ page, setPage }) => {
  const [t, i18n] = useTranslation();
  const [price, setPrice] = useState([]);
  const { loading, error, sendRequest: fetchTasks } = useHttp();

  useEffect(() => {
    const applyRes = (res) => {};

    const getData = (data) => {
      setPrice(data.data);
    };

    fetchTasks(
      {
        url: `https://backend.curecare.health/api/v1/services?page=1&per_page=60`,
      },
      applyRes,
      getData
    );
  }, [fetchTasks]);

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className="container">
        <div>
          <nav className={classes.breadcrumb}>
            <ul>
              <li className={classes["breadcrumb-item"]}>
                <Link to="/">{t("main")}</Link>
              </li>
              <li className={classes["breadcrumb-item active"]}>
                {t("price")}
              </li>
            </ul>
          </nav>
          <h3 className={classes.price}>{t("price")}</h3>
        </div>
        {price.map((item) => {
          return (
            <div key={item.id} className={classes.pricing}>
              <div className={classes.special}>
                <h4 className={classes.title}>{item.attributes.title}</h4>
                <span className={classes.text}>
                  {item.attributes.price} {t("coin")}
                </span>
              </div>
            </div>
          );
        })}

        <p className="errorMessage">{error}</p>
      </div>
    </div>
  );
};

export default Pricing;
