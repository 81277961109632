import React from "react";
import RadioItem from "./RadioItem";
import classes from "../NurseSuggestion.module.css";

const RadioButton = (props) => {
  const { label, name, options, ...rest } = props;
  return (
    <div className={classes["radio-item"]}>
      {options?.map((item, index) => {
        return <RadioItem key={index} name={name} item={item} index={index} />;
      })}
    </div>
  );
};

export default RadioButton;
