import React, { useState } from "react";
import classes from "../profileFormPage//ProfileForm.module.css";

import {
  RiPhoneLine,
  RiLockPasswordLine,
  RiDeleteBin5Line,
} from "react-icons/ri";
import { BiUser, BiEnvelope, BiMap } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import DeleteProfile from "../deletProfile/DeleteProfile";

const LabelForm = (props) => {
  const [t, i18n] = useTranslation();
  const edit = props.edit,
    setEdit = props.setEdit,
    setSaveEdit = props.setSaveEdit,
    profile = props.profile;

  const changeToInput = () => {
    setEdit(false);
    setSaveEdit(true);
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      {edit && (
        <div className="container">
          <div className={classes["remove-account"]}>
            <button className={classes.btn} onClick={changeToInput}>
              {t("edit")} <AiOutlineEdit />
            </button>
            <button className={classes.btn} onClick={() => setShow(true)}>
              {t("remove-profile")}
              <RiDeleteBin5Line />
            </button>
            <DeleteProfile show={show} setShow={setShow} />
          </div>
          <div className={classes.labelform}>
            <BiUser />
            <div className={classes.control}>
              <label className={classes.title}>{t("name")}</label>
              <label className={classes.text} type="text" name="name">
                {profile?.name}
              </label>
            </div>
          </div>
          <div className={classes.labelform}>
            <RiPhoneLine />
            <div className={classes.control}>
              <label className={classes.title}>{t("phone")}</label>
              <label className={classes.text} type="number" name="name">
                {profile?.phone}
              </label>
            </div>
          </div>
          <div className={classes.labelform}>
            <BiEnvelope />
            <div className={classes.control}>
              <label className={classes.title}>{t("email")}</label>
              <label className={classes.text} type="email" name="name">
                {profile?.email}
              </label>
            </div>
          </div>
          <div className={classes.labelform}>
            <BiMap />
            <div className={classes.control}>
              <label className={classes.title}>{t("address")}</label>
              <label className={classes.text} type="text" name="name">
                {profile?.address}
              </label>
            </div>
          </div>
          <div className={classes.labelform}>
            <RiLockPasswordLine />
            <div className={classes.control}>
              <label className={classes.title}>{t("password")}</label>
              <label className={classes.text} type="password" name="name">
                ****************
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LabelForm;
