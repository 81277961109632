import React, { useContext, useState } from "react";
import GoogleMapReact from "google-map-react";
import classes from "./Map.module.css";
import { RiMapPin3Fill } from "react-icons/ri";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/perspective.css";

const Map = ({ list, error, id, setId }) => {
  const [isCard, setIsCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [visible, setVisible] = useState(false);

  const toggleButton = () => {
    setVisible(!visible);
  };

  const ctx = useContext(AuthContext);
  const isLoggedIn = ctx.isLoggedIn;

  const showItem = (item) => {
    setId(item?.id);
  };

  return (
    <div className={classes.map}>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCCN1SPvf12IviIBDa_akmbdKq48Kfy8Pk",
          }}
          defaultCenter={ctx.coordinates}
          center={ctx.coordinates}
          defaultZoom={10}
          margin={[50, 50, 50, 50]}
          options={""}
          onChange={(e) => {
            ctx.setCoordinates({ lat: e.center.lat, lng: e.center.lng });
          }}
          onChildClick={(child) => {
            setCardData(list[child]);
            setIsCard(true);
          }}>
          {list.map((item, i) => {
            return (
              <div
                key={item.id}
                className={classes.location}
                lat={
                  item?.attributes?.location?.x
                    ? Number(item?.attributes?.location?.x)
                    : ""
                }
                lng={
                  item?.attributes?.location?.y
                    ? Number(item?.attributes?.location?.y)
                    : ""
                }>
                <Tippy
                  animation="perspective"
                  trigger="click"
                  interactive={true}
                  theme={"light"}
                  maxWidth="none"
                  content={
                    <Link
                      className={classes.linkto}
                      to={!isLoggedIn ? "/login" : `/nursePage/${item?.id}`}>
                      <h3>{item?.attributes?.name}</h3>
                      <p> {item?.attributes?.specialty_name}</p>
                    </Link>
                  }>
                  <button>
                    <RiMapPin3Fill
                      color="#02b0b1"
                      fontSize={"40"}
                      onMouseMove={() => showItem(item)}
                    />
                  </button>
                </Tippy>
              </div>
            );
          })}
          {/* {isCard && (
            <div className={classes.description}>
              <Link to={!isLoggedIn ? "/login" : `/nursePage/${cardData.id}`}>
                <div className={classes["nurse-image"]}>
                  <img
                    src={cardData?.attributes?.profile_image}
                    alt={cardData?.attributes?.name}
                  />
                </div>
              </Link>
              <h2>{cardData?.attributes?.name}</h2>
              <div>
                <AiFillCloseCircle
                  className={classes.close}
                  onClick={() => setIsCard(false)}
                />
              </div>
            </div>
          )} */}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
