import React, { useState, useCallback, useEffect } from "react";
import classes from "./Header.module.css";
import { FiBarChart, FiSearch } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const [t, i18n] = useTranslation();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [specialty, setSpecialty] = useState();

  const setGender = props.setGender,
    gender = props.gender,
    search = props.search,
    setSearch = props.setSearch,
    special = props.special,
    setSpecial = props.setSpecial;

  const fetchSpecial = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://backend.curecare.health/api/v1/specialties"
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      setSpecialty(data.data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchSpecial();
    }, 500);

    return () => clearTimeout(timer);
  }, [fetchSpecial]);

  if (isLoading) {
    return <div className="loading"></div>;
  }

  const handlePress = (e) => {
    if (e.keyCode === 13) {
      props.fetchData(props.page, gender, search, special);
    }
  };

  return (
    <div>
      <div className={classes.control}>
        <FiSearch className={classes.search} />
        <input
          type="search"
          placeholder={t("search-nurse")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handlePress}
        />
        <FiBarChart className={classes.chart} onClick={() => setShow(true)} />
      </div>
      {show && (
        <section>
          <div className={classes.filter} onClick={() => setShow(false)}></div>
          <div className={classes.show}>
            <button className={classes.close} onClick={() => setShow(false)}>
              <AiFillCloseCircle className={classes.icon} />
            </button>
            <div className={classes["radio-item"]}>
              <h5>{t("filter-type")}</h5>
              <label>
                <input
                  type="radio"
                  name="gender"
                  onClick={() => setGender("male")}
                />
                <span>{t("male")}</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  onClick={() => setGender("female")}
                />
                <span>{t("female")}</span>
              </label>
            </div>
            <h5>{t("filter-special")}</h5>
            {specialty.map((item) => {
              return (
                <div key={item.id} className={classes["radio-special"]}>
                  <label>
                    {item?.attributes?.name}
                    <span></span>
                    <input
                      type="radio"
                      name="special"
                      onClick={() => setSpecial(item?.id)}
                    />
                  </label>
                </div>
              );
            })}
            <div className={classes.submit}>
              <button
                className={classes["filter-btn"]}
                onClick={() =>
                  props.fetchData(props.page, gender, search, special)
                }>
                {t("filter")}
              </button>
              <button className={classes.btn} onClick={() => setShow(false)}>
                {t("cancel-filter")}
              </button>
            </div>
            <p className="errorMessage">{error}</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default Header;
