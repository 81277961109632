import React, { useContext, useState } from "react";
import classes from "../profileFormPage/ProfileForm.module.css";

import * as Yup from "yup";
import TextError from "../profileFormPage/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../profileFormPage/Validate.css";

import {
  RiPhoneLine,
  RiLockPasswordLine,
  RiDeleteBin5Line,
} from "react-icons/ri";
import { BiUser, BiEnvelope, BiMap } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import useHttp from "../../../hook/use-fetch";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import DeleteProfile from "../deletProfile/DeleteProfile";

const InputForm = (props) => {
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();
  const history = useHistory();

  const ctx = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const [t, i18n] = useTranslation();
  const setEdit = props.setEdit,
    saveEdit = props.saveEdit,
    setSaveEdit = props.setSaveEdit,
    profile = props.profile;

  const changeToLable = () => {
    setEdit(true);
    setSaveEdit(false);
  };

  const initialValues = {
    name: profile?.name,
    phone: profile?.phone,
    email: profile?.email,
    address: profile?.address,
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, t("too-short"))
      .max(50, t("too_long"))
      .required(t("filed-required")),
    phone: Yup.string().required(t("wrong-number")).min(10, t("short-number")),
    email: Yup.string().email(t("wrong-format")).required(t("filed-required")),
    address: Yup.string()
      .min(2, t("too-short"))
      .max(50, t("too_long"))
      .required(t("filed-required")),
    password: Yup.string()
      .required(t("enter-password"))
      .min(7, t("weak-password"))
      .matches(/[a-zA-Z]/, t("char-password")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("password-match")
    ),
  });

  const applyRes = (res) => {
    if (res.ok) {
      history.push("/update");
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: "https://backend.curecare.health/api/v1/patient/update",
        method: "PUT",
        headers: {
          Authorization: ctx.id,
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          patient: {
            name: values.name,
            phone: values.phone,
            address: values.address,
            password: values.password,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      {saveEdit && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          <Form>
            <div className="container">
              <div className={classes.isedit}>
                <button className={classes.cancel} onClick={changeToLable}>
                  {t("cancel")}
                </button>
                <button className={classes.save} type="submit">
                  {t("save")}
                </button>
              </div>
              <div className={classes.labelform}>
                <BiUser />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="name">
                    {t("name")}
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className={classes.formaction}
                    placeholder={t("name")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="name"></label>
                        <input
                          type="text"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("name")}
                          name="name"
                          id="name"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="name" component={TextError} />
              <div className={classes.labelform}>
                <RiPhoneLine />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="phone">
                    {t("phone")}
                  </label>
                  <Field
                    className={classes.formaction}
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder={t("phone")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="phone"></label>
                        <input
                          type="number"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("phone")}
                          name="phone"
                          id="phone"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="phone" component={TextError} />
              <div className={classes.labelform}>
                <BiEnvelope />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="email">
                    {t("email")}
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className={classes.formaction}
                    placeholder={t("email")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="email"></label>
                        <input
                          type="email"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("email")}
                          name="email"
                          id="email"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="email" component={TextError} />
              <div className={classes.labelform}>
                <BiMap />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="address">
                    {t("address")}
                  </label>
                  <Field
                    type="text"
                    name="address"
                    id="address"
                    className={classes.formaction}
                    placeholder={t("address")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="address"></label>
                        <input
                          type="text"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("address")}
                          name="address"
                          id="address"
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="address" component={TextError} />
              <div className={classes.labelform}>
                <RiLockPasswordLine />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="password">
                    {t("password")}
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className={classes.formaction}
                    placeholder={t("password")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="password"></label>
                        <input
                          type="password"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("password")}
                          name="password"
                          id="password"
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="password" component={TextError} />
              <div className={classes.labelform}>
                <RiLockPasswordLine />
                <div className={classes.control}>
                  <label className={classes.title} htmlFor="confirmPassword">
                    {t("confirm-password")}
                  </label>
                  <Field
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="on"
                    className={classes.formaction}
                    placeholder={t("password")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="confirmPassword"></label>
                        <input
                          type="password"
                          className={
                            touched && error ? "invalid" : "formaction"
                          }
                          {...field}
                          placeholder={t("password")}
                          name="confirmPassword"
                          id="confirmPassword"
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <ErrorMessage name="confirmPassword" component={TextError} />
              <p className="errorMessage">{error}</p>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default InputForm;
