import React, { useState } from "react";
import classes from "./Medicien.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import image from "../../../assests/Vector.png";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const Medicien = ({ formData, setFormData, setPage, page }) => {
  const [t, i18n] = useTranslation();

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const [enteredOrderTouched, setEnteredOrderTouched] = useState(false);
  const [enteredImageTouched, setEnteredImageTouched] = useState(false);

  const enteredOrderIsValid = formData.order.trim() !== "";
  const inputOrderIsValid = !enteredOrderIsValid && enteredOrderTouched;

  const enteredImageIsValid = formData.image !== "";
  const inputImageIsValid = !enteredImageIsValid && enteredImageTouched;

  let formISValid = false;

  if (enteredOrderIsValid || enteredImageIsValid) {
    formISValid = true;
  }

  const orderInputBlurHandler = (event) => {
    setEnteredOrderTouched(true);
  };

  const imageInputBlurHandler = (event) => {
    setEnteredImageTouched(true);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setEnteredOrderTouched(true);
    setEnteredImageTouched(true);

    if (!enteredOrderIsValid && !enteredImageIsValid) {
      return;
    }

    setPage(page + 1);

    setEnteredOrderTouched(false);
    setEnteredImageTouched(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const removeImageHandler = () => {
    setIsSelected(false);
    setFormData({ ...formData, image: "" });
  };

  const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} />;
  };

  return (
    <div>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div>
        <h2 className={classes.title}>{t("pharm")}</h2>
        <div>
          <p className={classes.order}>{t("order-medicien")}</p>
          <textarea
            onBlur={orderInputBlurHandler}
            value={formData.order}
            onChange={(e) =>
              setFormData({ ...formData, order: e.target.value })
            }
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder={t("your-medicien")}
          />
          {inputOrderIsValid && (
            <p className={classes.valid}>{t("medicien-error")}</p>
          )}

          <label className={classes["file-upload-label"]} htmlFor="upload">
            <img src={image} alt="file-upload" />
            {t("upload-image")}
          </label>
          {inputImageIsValid && (
            <p className={classes.valid}>{t("medicien-error")}</p>
          )}

          <input
            className={classes["file-upload-input"]}
            id="upload"
            type="file"
            accept="image/*"
            onChange={changeHandler}
            onBlur={imageInputBlurHandler}
          />

          {isSelected && (
            <div className={classes.image}>
              <AiFillCloseCircle
                className={classes.icon}
                fontSize={25}
                onClick={removeImageHandler}
              />
              {selectedFile && <ImageThumb image={selectedFile} />}
            </div>
          )}
          <div className={classes.next}>
            <button className={classes.nextbtn} onClick={onSubmitHandler}>
              {t("next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Medicien;
