import React, { useContext, useState } from "react";
import FacebookLogin from "react17-facebook-login";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Loginface.css";

const Loginface = () => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(AuthContext);

  const responseFacebook = (response) => {
    setError(false);
    setIsLoading(true);

    const fetchData = async () =>
      await fetch("https://backend.curecare.health/api/v1/patients/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          fb_token: response.accessToken,
        }),
      })
        .then((res) => {
          setIsLoading(false);
          ctx.login(res.headers.get("authorization"));
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = "Authentication failed !";
              if (data && data.status && data.status.message) {
                errorMessage = data.status.message;
              }
              throw new Error(errorMessage);
            });
          }
        })
        .then((data) => {
          history.replace("/");
        })
        .catch((err) => {
          setError(err.message);
        });
    fetchData();
  };

  if (isLoading) {
    return <div className="loading"></div>;
  }

  return (
    <React.Fragment>
      <FacebookLogin
        appId="734905534608208"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="my-facebook-button"
        textButton={t("login-facebook")}
        icon="fa-facebook"
      />
      <p className="errorMessage">{error}</p>
    </React.Fragment>
  );
};

export default Loginface;
