import React from "react";
import TextError from "../TextError";
import { Field, ErrorMessage } from "formik";
import TextInput from "./TextInput";
import classes from "../NurseSuggestion.module.css";

const RadioItem = (props) => {
  const { index, item, label, name, options, ...rest } = props;
  return (
    <div>
      <p className={classes["prop-name"]}>{item?.name}</p>
      <div className={classes["contnet-center"]}>
        <Field name={"q[" + index + "]"} {...rest}>
          {({ field }) => {
            return item?.values?.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <TextInput
                    field={field}
                    item={item}
                    option={option}
                    name={name}
                  />
                </React.Fragment>
              );
            });
          }}
        </Field>
        <ErrorMessage name={"q[" + index + "]"} component={TextError} />
      </div>
    </div>
  );
};

export default RadioItem;
