import React from "react";
import { Link } from "react-router-dom";

import image from "../../assests/nurse.png";
import image1 from "../../assests/pharm.png";
import image2 from "../../assests/lab.png";

import classes from "./HomePage.module.css";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const HomePage = () => {
  const [t, i18n] = useTranslation();
  return (
    <main>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <section className="section">
        <div className={classes["nurse-page"]}>
          <div className="container">
            <div className={classes.services}>
              <h1>{t("nurse-page-title")}</h1>
              <h4 className={classes.getorder}>{t("nurse-page-subtitle")}</h4>
              <p className={classes.name}>{t("nurse-page-description")}</p>
              <div className={classes.link}>
                <Link to="/nurse">{t("order")}</Link>
              </div>
            </div>
            <div className={classes["nurse-image"]}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className={classes["pharm-page"]}>
          <div className="container">
            <div className={classes.services}>
              <h1>{t("pharm-page-title")}</h1>
              <h4 className={classes.getorder}>{t("pharm-page-subtitle")}</h4>
              <p className={classes.name}>{t("pharm-page-description")}</p>
              <div className={classes.link}>
                <Link to="/pharm">{t("order")}</Link>
              </div>
            </div>
            <div className={classes["pharm-image"]}>
              <img src={image1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className={classes["pharm-page"]}>
          <div className="container">
            <div className={classes.services}>
              <h1>{t("lab")}</h1>
              <h4 className={classes.getorder}>{t("lab-info")}</h4>
              <p className={classes.name}>{t("lab-page-description")}</p>
              <div className={classes.link}>
                <a
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=201200116304"
                  target="_blank">
                  {t("order")}
                </a>
              </div>
            </div>
            <div className={classes["pharm-image"]}>
              <img src={image2} alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomePage;
