import "./App.css";
import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import AuthContext from "./store/auth-context";
import LoginPage from "./pages/loginPage/loginPage/LoginPage";
import SignupPage from "./pages/loginPage/signupPage/SignUpPage";
import ForgetPasswordPage from "./pages/loginPage/forgetPasswordPage/ForgetPasswordPage";
import ResetPasswordPage from "./pages/loginPage/resetPasswordPage/ResetPasswordPage";
import Layout from "./pages/navbarPage/Layout";
import Home from "./pages/mapPage/mainPage/Home";
import ProfileForm from "./pages/profilePage/profileFormPage/ProfileForm";
import Pharm from "./pages/medicienOrderPage/pharmPage/Pharm";
import NurseList from "./pages/mapPage/nurseList/NurseList";
import HomePage from "./pages/homePage/HomePage";
import NurseSuggestion from "./pages/nurseSuggestionPage/NurseSuggestion";
import LastPage from "./pages/latestOrderPage/LastPage";
import Pricing from "./pages/pricingPage/Pricing";
import Rate from "./pages/latestOrderPage/ratePage/Rate";
import Success from "./pages/successPage/Success";
import ErrorPage from "./pages/errorPage/ErrorPage";
import Update from "./pages/updateProfile/Update";
import Privacy from "./pages/privacyPage/Privacy";
import Review from "./pages/reviewPage/Review";
import TagManager from "react-gtm-module";

import "./i18n";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-TH6JFM5" });
  }, []);

  const ctx = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>

        <Route path="/signup">
          <SignupPage />
        </Route>

        <Route path="/reset_password">
          <ResetPasswordPage />
        </Route>

        <Route path="/forget-password">
          <ForgetPasswordPage />
        </Route>

        <Route path="/success">
          <Layout>
            <Success />
          </Layout>
        </Route>

        <Route path="/review">
          <Layout>
            <Review />
          </Layout>
        </Route>

        <Route path="/update">
          <Layout>
            <Update />
          </Layout>
        </Route>

        <Route exact path="/">
          <Layout>
            <HomePage />
          </Layout>
        </Route>

        <Route path="/nurse">
          <Layout>
            <Home />
          </Layout>
        </Route>

        <Route path="/nursePage/:id">
          <Layout>
            <NurseList />
          </Layout>
        </Route>

        <Route path="/pharm">
          <Layout>
            <Pharm />
          </Layout>
        </Route>

        <Route path="/nurseSuggest">
          <Layout>
            <NurseSuggestion />
          </Layout>
        </Route>

        <Route path="/latestOrder">
          <Layout>
            <LastPage />
          </Layout>
        </Route>

        <Route path="/pricing">
          <Layout>
            <Pricing />
          </Layout>
        </Route>

        <Route path="/rate">
          <Layout>
            <Rate />
          </Layout>
        </Route>

        <Route path="/Privacy">
          <Layout>
            <Privacy />
          </Layout>
        </Route>

        {ctx.isLoggedIn && (
          <Route path="/profile">
            <Layout>
              <ProfileForm />
            </Layout>
          </Route>
        )}

        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
