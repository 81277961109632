import React from "react";

import * as Yup from "yup";
import TextError from "../login/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../login/Validate.css";

import classes from "../login/Login.module.css";
import image from "../../../assests/Rectangle 37.png";

import { RiLockPasswordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHttp from "../../../hook/use-fetch";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

const ResetPasswordPage = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();
  const { pathname } = useLocation();
  const reset_password_token = pathname.split("/").pop().split("=")[1];

  const validationSchema = Yup.object({
    password: Yup.string().required(t("enter-password")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("password-match")
    ),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const applyRes = (res) => {
    if (res.ok) {
      history.replace("/update");
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: "https://backend.curecare.health/api/v1/patients/password",
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          patient: {
            reset_password_token: reset_password_token,
            password: values.password,
            password_confirmation: values.confirmPassword,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        <div className={classes.controls}>
          <Form className={classes.form}>
            <div className="container">
              <div className={classes.control}>
                <h2>{t("new-password")}</h2>
                <p>{t("enter-new-password")}</p>
                <div className={classes["form-control"]}>
                  <label htmlFor="password"></label>
                  <RiLockPasswordLine />
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder={t("password")}
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="password"></label>
                        <input
                          type="password"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("password")}
                          name="password"
                          id="password"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="password" component={TextError} />
                <div className={classes["form-control"]}>
                  <label htmlFor="confirmPassword"></label>
                  <RiLockPasswordLine />
                  <Field
                    type="password"
                    placeholder={t("confirm-password")}
                    name="confirmPassword"
                    id="confirmPassword"
                  >
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="password"></label>
                        <input
                          type="password"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("confirm-password")}
                          name="confirmPassword"
                          id="confirmPassword"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="confirmPassword" component={TextError} />
                <button className={classes.btn}>{t("renew-password")}</button>
                <Link to="/login" className={classes.forget} type="submit">
                  {t("enter-signin")}
                </Link>
                <Link to="/signup" type="button" className={classes.toggle}>
                  {t("no-account")}
                  <span> {t("new-account")} </span>
                </Link>
              </div>
            </div>
            <p className="errorMessage">{error}</p>
          </Form>
          <div className={classes.image}>
            <div className={classes.overlay}></div>
            <img src={image} alt="site" />
          </div>
        </div>
      </Formik>
    </>
  );
};

export default ResetPasswordPage;
