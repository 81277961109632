import React, { useContext } from "react";

import * as Yup from "yup";
import TextError from "../login/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../login/Validate.css";

import classes from "../login/Login.module.css";
import image from "../../../assests/Rectangle 37.png";

import { BiEnvelope } from "react-icons/bi";
import { Link } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import useHttp from "../../../hook/use-fetch";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const ForgetPasswordPage = () => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const { loading, error, sendRequest: sendTaskRequest } = useHttp();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t("wrong-format")).required(t("filed-required")),
  });

  const applyRes = (res) => {};

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: "https://backend.curecare.health/api/v1/patients/password",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: {
          patient: {
            email: values.email,
          },
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}>
        <div className={classes.controls}>
          <Form className={classes.form}>
            <div className="container">
              <div className={classes.control}>
                <span className={classes.forgettext}>
                  {t("password-recovery")}
                </span>
                <p>{t("send-message-recovery")}</p>
                <label className={classes.enter}>{t("enter-email")}</label>
                <div className={classes["form-control"]}>
                  <label htmlFor="email"></label>
                  <BiEnvelope />
                  <Field
                    type="email"
                    placeholder={t("email")}
                    name="email"
                    id="email">
                    {({ field, meta: { touched, error } }) => (
                      <>
                        <label htmlFor="email"></label>
                        <input
                          type="email"
                          className={touched && error ? "invalid" : ""}
                          {...field}
                          placeholder={t("email")}
                          name="email"
                          id="email"
                        />
                      </>
                    )}
                  </Field>
                </div>
                <ErrorMessage name="email" component={TextError} />
                <button type="submit" className={classes.btn}>
                  {t("send-link")}
                </button>
                <Link to="login" className={classes.forget} type="submit">
                  {t("enter-signin")}
                </Link>
                <Link to="signup" type="button" className={classes.toggle}>
                  {t("no-account")}
                  <span> {t("new-account")} </span>
                </Link>
              </div>
            </div>
            <p className="errorMessage">{error}</p>
          </Form>
          <div className={classes.image}>
            <div className={classes.overlay}></div>
            <img src={image} alt="site" />
          </div>
        </div>
      </Formik>
    </>
  );
};

export default ForgetPasswordPage;
