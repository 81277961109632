import React, { useCallback, useContext, useEffect, useState } from "react";
import LatestOrder from "./lastOrderPage/LatestOrder";
import Rate from "./ratePage/Rate";
import AuthContext from "../../store/auth-context";
import Helmet from "react-helmet";

const LastPage = () => {
  const [rate, setRate] = useState(true);
  const [saveRate, setSaveRate] = useState(false);
  const [lastOrder, setLastOrder] = useState([]);
  const ctx = useContext(AuthContext);
  const [item, setItem] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTasks = useCallback(async (page) => {
    let filterPerPage;
    if (page === undefined || page === null || page === "") {
      filterPerPage = 1;
    } else {
      filterPerPage = page;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://backend.curecare.health/api/v1/patients/histories?page=${filterPerPage}`,
        {
          headers: {
            Authorization: ctx.id,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      var nurseArr = [];

      for (let x = 0; x < data.data.length; x++) {
        for (let y = 0; y < data.included.length; y++) {
          if (
            data.data[x].attributes.nurse_id.toString() === data.included[y].id
          ) {
            const objTwo = {
              ...data.included[y],
              b: data.data[x].relationships,
            };
            nurseArr.push(objTwo);
          }
        }
      }
      setLastOrder(nurseArr);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchTasks();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <section>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <LatestOrder
        rate={rate}
        setRate={setRate}
        saveRate={saveRate}
        setSaveRate={setSaveRate}
        lastOrder={lastOrder}
        setLastOrder={setLastOrder}
        item={item}
        setItem={setItem}
        page={page}
        setPage={setPage}
        error={error}
        fetchTasks={fetchTasks}
      />

      <Rate
        rate={rate}
        setRate={setRate}
        saveRate={saveRate}
        setSaveRate={setSaveRate}
        lastOrder={lastOrder}
        setLastOrder={setLastOrder}
        item={item}
        error={error}
      />
    </section>
  );
};

export default LastPage;
