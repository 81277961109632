import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

import classes from "./NurseList.module.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import AuthContext from "../../../store/auth-context";
import CountCall from "./CountCall";

const NurseList = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const [review, setReview] = useState([]);
  const ctx = useContext(AuthContext);

  const infoFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend.curecare.health/api/v1/nurses/${id}`
      );
      if (response.status === 401) {
        ctx.logout();
        localStorage.clear();
        useHistory.replace("/login");
      }
      const data = await response.json();
      setInfo(data.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      infoFetch();
    }, 500);
    return () => clearTimeout(timer);
  }, [infoFetch]);

  const reviewFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend.curecare.health/api/v1/nurses/${id}/reviews`
      );
      if (response.status === 401) {
        ctx.logout();
        localStorage.clear();
        useHistory.replace("/login");
      }
      const data = await response.json();
      setIsLoading(false);
      setReview(data.data);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      reviewFetch();
    }, 500);
    return () => clearTimeout(timer);
  }, [reviewFetch]);

  if (isLoading) {
    return <div className="loading"></div>;
  }

  return (
    <div className={classes.section}>
      <Helmet>
        <title>{info?.attributes?.name ? info?.attributes?.name : ""}</title>
        <meta name="title" content={" الممرض " + info?.attributes?.name} />
        <meta
          name="description "
          content={" الممرض " + info?.attributes?.name}
        />
      </Helmet>
      <div className={classes.nurse}>
        <div className="container">
          <nav className={classes.breadcrumb}>
            <ul>
              <li className={classes["breadcrumb-item"]}>
                <Link to="/">{t("main")}</Link>
              </li>
              <li className={classes["breadcrumb-item active"]}>
                {t("nurse-pro")}{" "}
                {info?.attributes?.name ? info?.attributes?.name : ""}
              </li>
            </ul>
          </nav>
          <h2 className={classes.nurseProfile}>{t("nurse-profile")}</h2>
          <div className={classes.profile}>
            <div className={classes["profile-info"]}>
              <div className={classes.box}>
                <div className={classes.image}>
                  <img
                    src={
                      info?.attributes?.profile_image
                        ? info?.attributes?.profile_image
                        : ""
                    }
                    alt={info?.attributes?.name ? info?.attributes?.name : ""}
                  />
                </div>
                <div className={classes.contact}>
                  <h3 className={classes.name}>
                    {info?.attributes?.name ? info?.attributes?.name : ""}
                  </h3>
                  <span>
                    {info?.attributes?.specialty_name
                      ? info?.attributes?.specialty_name
                      : ""}
                  </span>
                  <div>
                    <ReactStars
                      value={info?.attributes?.avg_reviews}
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      edit={false}
                    />
                  </div>
                  <p>
                    {info?.attributes?.details ? info?.attributes?.details : ""}
                  </p>
                  <div className={classes.link}>
                    <CountCall info={info} id={id} />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <h3 className={classes.name}>
                {t("rates")} ({review.length})
              </h3>
            </div>
            {review?.map((item) => {
              return (
                <div className={classes["review-nurse"]} key={item.id}>
                  <div className="container">
                    <div className={classes.review}>
                      <div className={classes.nurse}>
                        <h5>
                          {item?.attributes?.patient.name
                            ? item?.attributes?.patient.name
                            : ""}
                        </h5>
                        <p>
                          {item?.attributes?.description
                            ? item?.attributes?.description.substring(0, 120)
                            : ""}
                        </p>
                      </div>
                      <div className={classes.rate}>
                        <ReactStars
                          value={item?.attributes?.rate}
                          count={5}
                          size={24}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={classes.newlink}>
              <button>
                <Link className={classes.linkto} to="/pricing">
                  {t("price")}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="errorMessage">{error}</p>
    </div>
  );
};

export default NurseList;
