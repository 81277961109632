import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../TextError";

const Textarea = (props) => {
  const { label, name, ...rest } = props;

  return (
    <div>
      <Field
        as="textarea"
        cols="30"
        rows="10"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Textarea;
