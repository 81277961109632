import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";

const CountCall = ({ info, id }) => {
  const [t, i18n] = useTranslation();
  const [title, setTitle] = useState(t("phone"));
  const ctx = useContext(AuthContext);

  const countCall = () => {
    fetch(`https://backend.curecare.health/api/v1/nurses/${id}/call`, {
      method: "PATCH",
      headers: {
        accept: "application/json",
      },
    })
      .then((res) => {
        if (res.status === 401) {
          ctx.logout();
          localStorage.clear();
          useHistory.replace("/login");
        }
      })
      .then((data) => {});
    setTitle(info?.attributes?.phone_number);
  };

  return (
    <React.Fragment>
      <a href={"tel:" + info?.attributes?.phone_number} onClick={countCall}>
        {title}
      </a>
    </React.Fragment>
  );
};

export default CountCall;
