import React from "react";
import classes from "../NurseSuggestion.module.css";

const TextInput = ({ name, field, item, option }) => {
  return (
    <div className={classes.item}>
      <label>
        <input
          type={item?.type === "multiple" ? "checkbox" : "radio"}
          id={item?.name}
          {...field}
          value={option?.name}
        />
        <span className={classes.choose}>{option?.name}</span>
      </label>
    </div>
  );
};

export default TextInput;
