import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (requestConfig, applyResponse, getData) => {
      setLoading(true);
      setError(null);
      await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "GET",
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      })
        .then((res) => {
          setLoading(false);
          applyResponse(res);
          if (res.status === 401) {
            localStorage.clear();
            useHistory.replace("/login");
          }
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = "Authentication failed !";
              if (data && data.status && data.status.message) {
                errorMessage = data.status.message;
              }
              throw new Error(errorMessage);
            });
          }
        })
        .then((data) => {
          getData(data);
        })
        .catch((err) => {
          setError(err.message);
        });
    },
    []
  );

  return {
    loading,
    error,
    sendRequest,
  };
};
export default useHttp;
