import React, { useContext } from "react";
import useHttp from "../../../hook/use-fetch";
import classes from "../profileFormPage/ProfileForm.module.css";

import * as Yup from "yup";
import TextError from "../../loginPage/login/TextError";
import { Field, Form, Formik, ErrorMessage } from "formik";
import "../../loginPage/login/Validate.css";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";

const DeleteProfile = ({ show, setShow }) => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const ctx = useContext(AuthContext);

  const { loading, error, sendRequest: sendTaskRequest } = useHttp();

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(t("enter-password")),
  });

  const applyRes = (res) => {
    if (res.ok) {
      history.replace("/");
      ctx.logout();
    }
  };

  const getData = (data) => {};

  const handleSave = async (values) => {
    sendTaskRequest(
      {
        url: `https://backend.curecare.health/api/v1/patients/signup?password=${values.password}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: ctx.id,
        },
      },
      applyRes,
      getData
    );
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div>
      {show && (
        <>
          <div className={classes.filter} onClick={() => setShow(false)}></div>
          <div className={classes.show}>
            <div className="container">
              <div className={classes["delete-user"]}>
                <p className={classes.del}>{t("remove-profile")}</p>
                <button
                  className={classes.close}
                  onClick={() => setShow(false)}
                >
                  <AiOutlineClose className={classes.icon} />
                </button>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSave}
              >
                <div className={classes.controls}>
                  <Form className={classes.form}>
                    <div className={classes.control}>
                      <p className={classes.txt}>{t("enter-to-remove")}</p>
                      <div className={classes["form-control"]}>
                        <label htmlFor="password"></label>
                        <RiLockPasswordLine />
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          placeholder={t("password")}
                        >
                          {({ field, meta: { touched, error } }) => (
                            <>
                              <label htmlFor="password"></label>
                              <input
                                type="password"
                                className={touched && error ? "invalid" : ""}
                                {...field}
                                placeholder={t("password")}
                                name="password"
                                id="password"
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      <ErrorMessage name="password" component={TextError} />
                      <button type="submit" className={classes.btns}>
                        {t("remove-profile")}
                      </button>
                    </div>
                    <p className="errorMessage">{error}</p>
                  </Form>
                </div>
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeleteProfile;
