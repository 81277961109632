import React from "react";

import { Field, ErrorMessage } from "formik";
import TextError from "../TextError";

const Input = (props) => {
  const { label, name, ...rest } = props;
  return (
    <React.Fragment>
      <Field id={name} name={name} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </React.Fragment>
  );
};

export default Input;
