import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classes from "./LatestOrder.module.css";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import LatestOrderItem from "./LatestOrderItem";
import AuthContext from "../../../store/auth-context";

const LatestOrder = ({
  lastOrder,
  rate,
  setRate,
  saveRate,
  setSaveRate,
  item,
  setItem,
  error,
  page,
  setPage,
  fetchTasks,
}) => {
  const [t, i18n] = useTranslation();
  const ctx = useContext(AuthContext);
  const isLoggedIn = ctx.isLoggedIn;

  const rateHandler = (item) => {
    setSaveRate(true);
    setRate(false);
    setItem(item);
  };

  const changeHandler = () => {
    setPage((currPage) => currPage + 1);
    fetchTasks(page);
  };

  return (
    <section className={classes.lastest}>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      {rate && (
        <>
          <div className="container">
            <div>
              <nav className={classes.breadcrumb}>
                <ul>
                  <li className={classes["breadcrumb-item"]}>
                    <Link to="/">{t("main")}</Link>
                  </li>
                  <li className={classes["breadcrumb-item active"]}>
                    {t("latestOrder")}
                  </li>
                </ul>
              </nav>
            </div>

            {lastOrder.length > 0 && isLoggedIn ? (
              lastOrder.map((item) => {
                return (
                  <div key={item.id}>
                    <LatestOrderItem item={item} rateHandler={rateHandler} />
                    <p>{error}</p>
                  </div>
                );
              })
            ) : (
              <div className={classes.newlink}>
                <button>
                  <Link className={classes.linkto} to="/nurse">
                    {t("order-nurse")}
                  </Link>
                </button>
              </div>
            )}
          </div>
          {lastOrder.length > 0 && isLoggedIn ? (
            <div className={classes.center}>
              <button onClick={changeHandler} className={classes.more}>
                {t("more")}
              </button>
            </div>
          ) : null}
        </>
      )}
    </section>
  );
};

export default LatestOrder;
