import React from "react";
import classes from "./LatestOrder.module.css";
import { useTranslation } from "react-i18next";

const LatestOrderItem = ({ item, rateHandler }) => {
  const [t, i18n] = useTranslation();

  return (
    <div>
      <div className={classes.last}>
        <div className={classes.profile}>
          <div className={classes.image}>
            <img
              src={item?.attributes?.profile_image}
              alt={item?.attributes?.name}
            />
          </div>
          <div className={classes.info}>
            <h4 className={classes.name}>{item?.attributes?.name}</h4>
            <p className={classes.type}>{item?.attributes?.specialty_name}</p>
          </div>
        </div>
        <div className={classes.rate}>
          <button
            disabled={item?.b?.review?.data !== null}
            onClick={() => rateHandler(item)}
            className={
              item?.b?.review?.data === null
                ? classes.link
                : classes.linkdisable
            }>
            {item?.b?.review?.data === null ? t("rate") : t("rate-done")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestOrderItem;
