import React, { useContext, useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper/core";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";
import "./Nurse.css";
import AuthContext from "../../../store/auth-context";
import Helmet from "react-helmet";

SwiperCore.use([Navigation, Autoplay]);

const Nurse = ({ list, page, setPage, error, id, setId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const ctx = useContext(AuthContext);
  const isLoggedIn = ctx.isLoggedIn;

  if (isLoading) {
    return <div className="loading"></div>;
  }

  return (
    <article>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className="nursepage">
        <button
          disabled={page === list.length - 1}
          onClick={() => {
            setPage((currPage) => currPage + 1);
          }}>
          <FaArrowAltCircleRight
            className={page === list.length - 1 ? "invalid-error" : "icon"}
            disabled={page === list.length - 1}
          />
        </button>
        <Swiper
          autoplay={{ delay: "2000" }}
          spaceBetween={30}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            567: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            767: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            991: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}>
          {list.map((item) => (
            <div key={item.id} className="container">
              <SwiperSlide key={item.id}>
                <Link
                  className="linkto"
                  to={!isLoggedIn ? "/login" : `/nursePage/${item.id}`}
                  key={item.id}>
                  <div className={item?.id === id ? "box border" : "box"}>
                    <div className="container">
                      <div className="image">
                        <img
                          src={item?.attributes?.profile_image}
                          alt={item?.attributes?.name}
                        />
                      </div>
                      <h3 className="name">{item?.attributes?.name}</h3>
                      <p className="special">
                        {item?.attributes?.specialty_name}
                      </p>
                      <div className="star">
                        <ReactStars
                          value={item?.attributes?.avg_reviews}
                          count={5}
                          size={24}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
        <button
          disabled={page === 1}
          onClick={() => {
            setPage((currPage) => currPage - 1);
          }}>
          <FaArrowAltCircleLeft
            className={page === 1 ? "invalid-error" : "icon"}
            disabled={page === 1}
          />
        </button>
      </div>
      <p className="errorMessage">{error}</p>
    </article>
  );
};

export default Nurse;
