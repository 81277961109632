import React, { useState } from "react";
import classes from "./Address.module.css";
import { AiOutlineEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

const Adress = ({
  formData,
  setFormData,
  page,
  setPage,
  address,
  setAddressValue,
}) => {
  const [t, i18n] = useTranslation();
  const [edit, setEdit] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const changeToInput = () => {
    setEdit(false);
    setIsEdit(true);
  };

  const [enteredAddressTouched, setEnteredAddressTouched] = useState(false);
  const [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false);

  const enteredAddressIsValid = formData.address.trim() !== "";
  const inputAddressIsValid = !enteredAddressIsValid && enteredAddressTouched;

  const enteredPhoneIsValid = formData.phone.trim() !== "";
  const inputPhoneIsValid = !enteredPhoneIsValid && enteredPhoneTouched;

  let formISValid = false;

  if (enteredAddressIsValid && enteredPhoneIsValid) {
    formISValid = true;
  }

  const addressInputBlurHandler = (event) => {
    setEnteredAddressTouched(true);
  };

  const phoneInputBlurHandler = (event) => {
    setEnteredPhoneTouched(true);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setEnteredAddressTouched(true);
    setEnteredPhoneTouched(true);

    if (!enteredAddressIsValid) {
      return;
    }

    if (!enteredPhoneIsValid) {
      return;
    }

    setPage(page + 1);

    setEnteredAddressTouched(false);
    setEnteredPhoneTouched(false);
  };

  return (
    <div>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div>
        <h2 className={classes.title}>{t("pharm")}</h2>
        <p className={classes.order}>{t("add-address")}</p>
        {edit && (
          <div className={classes.editaddress}>
            <div className={classes.isedit}>
              <h3 className={classes.edit}>{t("current-address")}</h3>
              <AiOutlineEdit onClick={changeToInput} />
            </div>
            <div className={classes.currentaddress}>
              <label>{address ? address : t("your-address")}</label>
            </div>
          </div>
        )}

        {isEdit && (
          <div className={classes["add-address"]}>
            <input
              className={classes.input}
              type="text"
              name="address"
              value={address !== null && address !== undefined ? address : ""}
              onBlur={addressInputBlurHandler}
              onChange={(e) => setAddressValue(e.target.value)}
            />
          </div>
        )}

        {inputAddressIsValid && (
          <p className={classes.valid}>{t("filed-required")}</p>
        )}

        <div className={classes["add-address"]}>
          <p className={classes.order}>ادخل رقم هاتفك</p>
          <input
            className={classes.input}
            type="number"
            name="phone"
            placeholder="رقم هاتفك"
            value={formData.phone}
            onBlur={phoneInputBlurHandler}
            onChange={(e) =>
              setFormData({ ...formData, phone: e.target.value })
            }
          />
        </div>

        {inputPhoneIsValid && (
          <p className={classes.valid}>{t("filed-required")}</p>
        )}

        <div className={classes.next}>
          <button className={classes.nextbtn} onClick={onSubmitHandler}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Adress;
