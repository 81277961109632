import React from "react";
import { Link } from "react-router-dom";
import classes from "./ErrorPage.module.css";
import { useTranslation } from "react-i18next";
import image from "../../assests/http-404-error.png";
import Helmet from "react-helmet";

const Success = () => {
  const [t, i18n] = useTranslation();

  return (
    <main>
      <Helmet>
        <title>CureCare App</title>
      </Helmet>
      <div className={classes.success}>
        <div className={classes.image}>
          <img src={image} alt="404 Not Found!" />
          <h2 className={classes.message}>{t("error-page")} </h2>
        </div>
        <button className={classes.btn}>
          <Link className={classes.link} to="/">
            {t("back-home")}
          </Link>
        </button>
      </div>
    </main>
  );
};

export default Success;
